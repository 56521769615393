import { createSlice } from "@reduxjs/toolkit";

export const rightDrawerSlice = createSlice({
  name: "rightDrawer",
  initialState: {
    header: "",
    isOpen: false,
    bodyType: "",
    role: "",
    userDetail: {},
    extraObject: {},
  },
  reducers: {
    openRightDrawer: (state, action) => {
      const { header, bodyType, extraObject } = action.payload;
      state.isOpen = true;
      state.bodyType = bodyType;
      state.header = header;

      state.extraObject = extraObject;
    },

    closeRightDrawer: (state, action) => {
      state.isOpen = false;
      state.bodyType = "";
      state.header = "";
      state.extraObject = {};
    },

    setRole: (state, action) => {
      state.role = action.payload.role;
    },
    setUserDetail: (state, action) => {
      state.userDetail = action.payload.userDetail;
    },
    getUserDetail: (state) => {
      return state.userDetail;
    },
    getRole: (state) => {
      return state.role;
    },
  },
});

export const {
  openRightDrawer,
  closeRightDrawer,
  getRole,
  setRole,
  setUserDetail,
  getUserDetail,
} = rightDrawerSlice.actions;

export default rightDrawerSlice.reducer;

import axios from "axios";
import { jwtDecode } from "jwt-decode";

// Define public routes that do not require authentication
const PUBLIC_ROUTES = [
  "login",
  "forgot-password",
  "register",
  "/cardDetail/*",
  "/",
];

const isPublicRoute = (pathname) => {
  return PUBLIC_ROUTES.some((route) => {
    if (route.includes("*")) {
      // Remove the wildcard and check if the pathname starts with the route
      return pathname.startsWith(route.replace("*", ""));
    }
    return route === pathname;
  });
};

const checkAuth = () => {
  // Get the token from localStorage
  const TOKEN = localStorage.getItem("access_token");

  // Check if the current page is a public route
  const isPublicPage = isPublicRoute(window.location.pathname);

  // If there is no token and the current page is not public, redirect to login
  if (!TOKEN) {
    if (!isPublicPage) {
      window.location.href = "/";
    }
    return false;
  }

  try {
    const decodedToken = jwtDecode(TOKEN);
    const currentTime = Math.floor(Date.now() / 1000);


    if (decodedToken.exp < currentTime) {
      localStorage.removeItem("access_token");
      if (!isPublicPage) {
        window.location.href = "/";
      }
      return false;
    }

    axios.defaults.headers.common["Authorization"] = `Bearer ${TOKEN}`;

    axios.interceptors.request.use(
      (config) => {
        document.body.classList.add("loading-indicator");
        return config;
      },
      (error) => {
        document.body.classList.remove("loading-indicator");
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        document.body.classList.remove("loading-indicator");
        return response;
      },
      (error) => {
        document.body.classList.remove("loading-indicator");

        if (error.response && error.response.status === 401) {
          // localStorage.removeItem("access_token");
          if (!isPublicPage) {
            window.location.href = "/";
          }
        }
        return Promise.reject(error);
      }
    );

    return true;
  } catch (error) {
    console.error("Invalid token:", error);
    localStorage.removeItem("access_token");
    if (!isPublicPage) {
      window.location.href = "/";
    }
    return false;
  }
};

export default checkAuth;

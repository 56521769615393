import { createSlice } from '@reduxjs/toolkit';

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    subscriptionData: null,
    subscriptionTypes: []  // Corrected typo here
  },
  reducers: {
    setSubscriptionData: (state, action) => {
      state.subscriptionData = action.payload;
    },
    setSubscriptionTypes: (state, action) => {
      state.subscriptionTypes = action.payload;  // Corrected typo here
    },
    clearSubscriptionData: (state) => {
      state.subscriptionData = null;
    },
  },
});

export const { setSubscriptionData, clearSubscriptionData, setSubscriptionTypes } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;

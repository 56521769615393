import { configureStore } from '@reduxjs/toolkit';
import headerSlice from '../features/common/headerSlice';
import modalSlice from '../features/common/modalSlice';
import rightDrawerSlice from '../features/common/rightDrawerSlice';
import subscriptionSlice from '../features/common/subscriptionSlice';

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice, 
  subscription: subscriptionSlice,
};

export default configureStore({
  reducer: combinedReducer,
});

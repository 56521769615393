import { lazy, Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

function CardDetail() {
  const { id } = useParams();
  const [userDetail, setUserDetail] = useState(null);
  const TemplateOne = lazy(() => import("./protected/templates/templateOne"));
  const TemplateTwo = lazy(() => import("./protected/templates/templateTwo"));
  useEffect(() => {
    const fetchData = async () => {
      const result = await axios.get("/user/getPublicUser/" + id);

      if (result.data && result.data.message === "success") {
        setUserDetail(() => {
          return { ...result.data.data };
        });
      }
    };

    fetchData();
  }, [id]);
  const renderTemplate = () => {
    switch (userDetail?.activeTemplate) {
      case "templateOne":
        return <TemplateOne userDetail={userDetail} />;
      case "templateTwo":
        return <TemplateTwo userDetail={userDetail} />;
      default:
        return <p>Unknown template</p>;
    }
  };
  return (
    <>
      {userDetail ? (
        <Suspense fallback={<p>Loading template...</p>}>
          {renderTemplate()}
        </Suspense>
      ) : (
        // <p>{userDetail?.firstName}</p>
        <p>Loading...</p>
      )}
    </>
  );
}

export default CardDetail;

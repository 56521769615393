import { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { themeChange } from 'theme-change'
import checkAuth from './app/auth';
import initializeApp from './app/init';
import CardDetail from './pages/CardDetail';
import LandingPage from './pages/Landing';

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const Register = lazy(() => import('./pages/Register')) 


initializeApp()


const token = checkAuth()


function App() {

  useEffect(() => {
    themeChange(true)
  }, [])


  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/cardDetail/:id" element={<CardDetail />} />
          <Route path="/" element={<LandingPage />} />
          
          {/* Place new routes over this */}
          <Route path="/*" element={<Layout />} />

          <Route path="*" element={<Navigate to={token ? "/welcome" : "/login"} replace />}/>

        </Routes>
      </Router>
    </>
  )
}

export default App
